import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const {
  CUSTOMERS_OVERVIEW_GRAPHQL_URI,
  CUSTOMER_OVERVIEW_V3_URI,
  SHOW_QUERY_DEBUG,
  NOVA_SDK_SRC,
  NOVA_SDK_API_URI,
  NOVA_SDK_INAPP_API_URI,
  NOVA_SDK_PUSH_SUBSCRIBE_URI,
  NOVA_SDK_CLICK_TRACKER_URI,
  APPLICATION_CODE,
  BASE_URI,
  WEB_URI,
  OAUTH_CLIENT_ID,
  OAUTH_CLIENT_SECRET,
  OAUTH_SERVICE_URI,
  AUTH_SERVICE_URI,
  VISION_SERVICE_URI,
  MARKETING_SERVICE_URI,
  CLUSTERING_SERVICE_URI,
  NHUB_SERVICE_URI,
  NOVA_ENV,
  VERSION,
  UPLOAD_SERVICE_URI,
  LOOKER_HOST,
  LOOKER_SECRET,
  SIMULATE_URI,
  AUTH_FORM_URI,
  AUTHORIZATION_CODE_REDIRECT_URI,
  AUTHORIZATION_CODE_REDIRECT_URI_SILENT,
  WEBMANAGER_URI,
  SIMULATE_REPORT_URI,
  MIXPANEL_TOKEN,
  USER_PREFERENCES_URI,
  HR_BOT_BO_URI,
  CUSTOMER_URI,
  TOGGLE_FEATURE_BO_URI,
  RECOMMENDATIONS_REPORT_URI,
  ELEVIO_ACCOUNT_ID,
  CAMPAIGN_BONUS_URI,
  UNLAYER_PROJECT_ID,
  BET9JA_PROD_ID,
  BET9JA_STG_ID,
  YAJUEGO_PROD_ID,
  YAJUEGO_STG_ID,
  AUDIENCE_MANAGER_URI,
  TOGGLE_FEATURE_TOKEN,
  SENTRY_DSN_URI,
  BONUS_INTEGRATION_URI,
  MKTOOL_ADAPTOR_URI,
  RELEASE_CHECK_URI,
  K8_BASE_URI,
  REPORT_URI,
  RESPONSIBLE_GAMBLING_URI,
  MOZZARTBET_STG_ID,
  MOZZARTBET_PROD_ID,
  MOZZARTBET_SERBIA_STG_ID,
  MOZZARTBET_SERBIA_PROD_ID,
  SOCCCERBET_SERBIA_STG_ID,
  SOCCCERBET_SERBIA_PROD_ID,
  TEAPUESTO_STG_ID,
  TEAPUESTO_PROD_ID,
  TEAPUESTO_PERU_PROD_ID,
  TEAPUESTO_PERU_STG_ID,
  GAME_ASSETS_URI,
  K8_DS_BASE_URI,
  ACTIVATION_SERVICE_URI,
  LANDING_PAGE_URI,
  GLI_CERTIFICATION_STG_ID,
  FRAUD_DETECTION_URI,
  SPIN_AND_WIN_PROD_ID,
  SPIN_AND_WIN_STG_ID,
  MAYBETS_KENYA_STG_ID,
  MAYBETS_KENYA_PROD_ID,
  MAMABET_STG_ID,
  MAMABET_PROD_ID,
  MOZZARTBET_CROATIA_STG_ID,
  MOZZARTBET_CROATIA_PROD_ID,
  GAMING_HUB_STG_ID,
  GAMING_HUB_PROD_ID,
  BET9JA_SHOP_APP_STG_ID,
  BET9JA_SHOP_APP_PROD_ID,
  ARISTOCRAT_ZIMBABWE_STG_ID,
  ARISTOCRAT_GABON_STG_ID,
} = publicRuntimeConfig

export default {
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
}

// eslint-disable-next-line import/no-mutable-exports
let version: string | null = 'local'
if (VERSION && VERSION.startsWith('refs/tags/')) {
  version = VERSION.slice(10)
}
if (VERSION && VERSION.startsWith('refs/heads/')) {
  version = VERSION.slice(11)
}

const baseUrl = BASE_URI || ''
const k8BaseUrl = K8_BASE_URI || ''
const k8DSBaseUrl = K8_DS_BASE_URI || ''

const webUri = WEB_URI || 'https://hub.novafutur.com'
const siteConfig = {
  siteName: 'NOVAFUTUR',
  siteIcon: 'ion-flash',
  dashboard: '/',
  footerText: `Powered by Novafutur ©${new Date().getFullYear()}. Version ${version}`,
}
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
}
const showQueryDebug = SHOW_QUERY_DEBUG || false
const novaEnv = NOVA_ENV

const sdk = {
  sdkSrc: NOVA_SDK_SRC || 'https://nova-hub-dev.web.app/nova-sdk-snippet.js',
  applicationCode: APPLICATION_CODE || 'xxxx-xxxx',
  sdkApiUri:
    NOVA_SDK_API_URI ||
    'https://europe-west2-dev-mlviewer.cloudfunctions.net/api',
  inappMessagesUrl:
    NOVA_SDK_INAPP_API_URI ||
    'https://europe-west2-dev-mlviewer.cloudfunctions.net/api',
  pushSubscribeUri:
    NOVA_SDK_PUSH_SUBSCRIBE_URI ||
    'https://europe-west2-dev-mlviewer.cloudfunctions.net/api/subscribe',

  popupClickTrackerUrl:
    NOVA_SDK_CLICK_TRACKER_URI ||
    'https://hub.svcs.dev.futurfly.com/user-track/api',
}

const langDir = 'ltr'
const jwtConfig = {
  clientId: OAUTH_CLIENT_ID || '11b7f69e-e07e-4aea-8813-24fee73cc2ed',
  clientSecret: OAUTH_CLIENT_SECRET || 'f7bf83cf-a4fe-4ce9-b08c-06065b1b21ce',
}
// get it from api directly just for now it here
const services = {
  oauth: OAUTH_SERVICE_URI || '/oauth',
  auth: AUTH_SERVICE_URI || '/auth/v1',
  vision: VISION_SERVICE_URI || '/vision/api/v1',
  nHub:
    NHUB_SERVICE_URI ||
    'https://notificationhub-dot-dev-267115.appspot.com/api/',
  upload: UPLOAD_SERVICE_URI || '/upload/v1',
  marketing: MARKETING_SERVICE_URI || '/marketing/api/v2',
  clustering: CLUSTERING_SERVICE_URI || '/clustering/v1',
  reporting: REPORT_URI || '/report/api/v1',
  simulate:
    SIMULATE_URI ||
    'https://simulate-api-node-dot-dev-simulate.appspot.com/api',
  simulateReports:
    SIMULATE_REPORT_URI ||
    'https://simulate-reports-dev-dot-dev-simulate.ew.r.appspot.com',
  userPreferences: USER_PREFERENCES_URI || '/userpref/api/v1',
  hrBotBo: HR_BOT_BO_URI || `${baseUrl}/hr-bot-bo`,
  customers:
    CUSTOMER_URI || 'https://customer-overview-stg-k24le57fqa-ew.a.run.app/',
  customersV3:
    CUSTOMER_OVERVIEW_V3_URI ||
    'https://dl.svcs.dev.futurfly.com/customer-overview',
  toggleFeature: TOGGLE_FEATURE_BO_URI || `${baseUrl}/toggle-feature`,
  recommendationsReport:
    RECOMMENDATIONS_REPORT_URI ||
    'https://logispin.recommendations.stg.novafutur.com',
  campaignBonus: CAMPAIGN_BONUS_URI || 'https://campaigns.dl.dev.novafutur.com',
  audienceManager:
    AUDIENCE_MANAGER_URI || 'https://audience-manager.dl.dev.novafutur.com',
  bonusIntegration:
    BONUS_INTEGRATION_URI || 'https://bonus-integration.dev.futurfly.com',
  mktoolAdaptor:
    MKTOOL_ADAPTOR_URI || 'https://hub.svcs.dev.futurfly.com/mktool-adapter/v1',
  releaseCheck:
    RELEASE_CHECK_URI ||
    'https://hub.svcs.dev.futurfly.com/release-orchestrator/api',
  responsibleGambling:
    RESPONSIBLE_GAMBLING_URI ||
    'https://ds.svcs.dev.futurfly.com/responsible-gambling',

  fraudDetection:
    FRAUD_DETECTION_URI ||
    'https://ds.svcs.stg.futurfly.com/ml-fraud-detection',

  activation: ACTIVATION_SERVICE_URI || '/market-campaign-optimiser/v1',
  landingPage:
    LANDING_PAGE_URI ||
    ' https://hub.svcs.dev.futurfly.com/landing-pages-service/api/v1',
}
const sentryDsn =
  SENTRY_DSN_URI ||
  'https://b8edcf1136074238afd0b9ff337d24fc@o1353005.ingest.sentry.io/6662282'

const toggleFeatureToken =
  TOGGLE_FEATURE_TOKEN ||
  '8a2b5a2bff3201a024d545aeb7af765855b8bf8615e2e198f3119c2156f3ff9a'

const customersOverviewGraphqlUri =
  CUSTOMERS_OVERVIEW_GRAPHQL_URI ||
  'https://dl.svcs.dev.futurfly.com/customers-overview/graphql/query'

const authenticationServerUri =
  AUTH_SERVICE_URI || 'https://auth.stg.novafutur.com/api/v1'
const authenticationFormUri =
  AUTH_FORM_URI || 'https://welcome.stg.novafutur.com'
const redirectUri = AUTHORIZATION_CODE_REDIRECT_URI
const redirectUriSilent = AUTHORIZATION_CODE_REDIRECT_URI_SILENT

const looker = {
  host: LOOKER_HOST || 'analytics.hub.novafutur.com',
  secret:
    LOOKER_SECRET ||
    '5f193c4ccef722c1e43bfe5512c97f20681effc84634cabbff65f54310fbb770',
}

const webManagerURI = WEBMANAGER_URI
const mixpanelToken = MIXPANEL_TOKEN
const elevioAccountId = ELEVIO_ACCOUNT_ID

const unlayerProjectId = UNLAYER_PROJECT_ID || 31140
const bet9jaProdId = BET9JA_PROD_ID || '2b40e08d-0820-49d4-aad5-63c7a4636ce2'
const bet9jaStgId = BET9JA_STG_ID || 'ee5c30fa-4a1b-4068-a012-a3b49d145563'
const yaJuegoProdId = YAJUEGO_PROD_ID || '6c6569d8-d83e-4e96-a297-24cda1f26180'
const yaJuegoStgId = YAJUEGO_STG_ID || '89b88ea6-9010-4c8e-b782-97934d8fcc1b'
const mozzartbetStgId =
  MOZZARTBET_STG_ID || '39097bd2-6077-4ac1-93e4-65f5be15d4bc'
const mozzartbetProdId =
  MOZZARTBET_PROD_ID || 'a71ec8cc-2375-4a19-b40d-0abbccf73b64'
const spinAndWinProdId =
  SPIN_AND_WIN_PROD_ID || '87f30c9a-a9dd-447e-af8b-d5cf4469e8b6'
const spinAndWinStgId =
  SPIN_AND_WIN_STG_ID || 'f3da72b8-7d9e-4776-96c6-06d80f351c16'

const mozzartbetSerbiaStgId =
  MOZZARTBET_SERBIA_STG_ID || '8e7ff376-7a13-4612-9971-e96be5374477'
const mozzartbetSerbiaProdId =
  MOZZARTBET_SERBIA_PROD_ID || 'not-setup-yet-MOZZARTBET_SERBIA'

const mozzartbetCroatiaStgId =
  MOZZARTBET_CROATIA_STG_ID || '26c8d4ec-34b4-4acb-a0b5-0939ad15a8d6'
const mozzartbetCroatiaProdId =
  MOZZARTBET_CROATIA_PROD_ID || 'c5b49b16-b615-48cf-87af-cf8950aa472f'

const soccerbetSerbiaStgId =
  SOCCCERBET_SERBIA_STG_ID || '461ca2bc-f5e2-45ce-996b-00fbaaec5389'
const soccerbetSerbiaProdId =
  SOCCCERBET_SERBIA_PROD_ID || 'c122ac3b-d1aa-4ac3-a394-27b80f143c0b'

const gameAssetsUrlEnvVar =
  GAME_ASSETS_URI || 'https://game-assets.stg.hub.novafutur.com'
const gliCertificationStgId =
  GLI_CERTIFICATION_STG_ID || '2d03a7a6-c63c-4668-b179-26a329db60b2'

const teapuestoStgId =
  TEAPUESTO_STG_ID || '7256d029-b659-43ae-8451-e75b9531df3d'
const teapuestoProdId =
  TEAPUESTO_PROD_ID || '619cc257-9881-4edd-b379-439e27ce0a3d'
const teapuestoPeruProdId =
  TEAPUESTO_PERU_PROD_ID || '58801c29-6e34-4b05-9ff1-741d3491a11c'
const teapuestoPeruStgId =
  TEAPUESTO_PERU_STG_ID || '06af9ac6-3127-11ee-be56-0242ac120002'

const maybetsKenyaStgId =
  MAYBETS_KENYA_STG_ID || 'aca6bf2b-bbde-4522-846d-65fd8c08ff8e'
const maybetsKenyaProdId =
  MAYBETS_KENYA_PROD_ID || '8658b1e0-4293-4ba3-9057-60ac035cb603'

const mamabetStgId = MAMABET_STG_ID || '73bda96e-698a-4a43-8697-44634d0eccfe'
const mamabetProdId = MAMABET_PROD_ID || 'a6cfc664-d015-4508-b43e-c612fd84a6fc'
const aristocratZimbabweStgId =
  ARISTOCRAT_ZIMBABWE_STG_ID || 'cda24b59-7259-41cb-9dd3-ec8425ff921c'
const aristocratGabonStgId =
  ARISTOCRAT_GABON_STG_ID || 'cda24b59-7259-41cb-9dd3-ec8425ff921c'

const gamingHubStgId =
  GAMING_HUB_STG_ID || 'ac9c2a93-97ac-4266-98aa-f59730c747c5'
const gamingHubProdId =
  GAMING_HUB_PROD_ID || '19d34fac-783a-4f5d-886c-11d5b33d68d2'
const bet9jaShopAppStgId =
  BET9JA_SHOP_APP_STG_ID || '7177e90d-ecfe-4078-a96e-2db3216fdea4'
const bet9jaShopAppProdId =
  BET9JA_SHOP_APP_PROD_ID || '7b428e0c-b7b0-4c0c-ba22-7d887b9bc3e2'

export {
  authenticationFormUri,
  authenticationServerUri,
  baseUrl,
  bet9jaProdId,
  bet9jaShopAppProdId,
  bet9jaShopAppStgId,
  bet9jaStgId,
  customersOverviewGraphqlUri,
  elevioAccountId,
  gameAssetsUrlEnvVar,
  gamingHubProdId,
  gamingHubStgId,
  gliCertificationStgId,
  jwtConfig,
  k8BaseUrl,
  k8DSBaseUrl,
  langDir,
  looker,
  mamabetProdId,
  mamabetStgId,
  maybetsKenyaProdId,
  maybetsKenyaStgId,
  mixpanelToken,
  mozzartbetCroatiaProdId,
  mozzartbetCroatiaStgId,
  mozzartbetProdId,
  mozzartbetSerbiaProdId,
  mozzartbetSerbiaStgId,
  mozzartbetStgId,
  novaEnv,
  redirectUri,
  redirectUriSilent,
  sdk,
  sentryDsn,
  services,
  showQueryDebug,
  siteConfig,
  soccerbetSerbiaProdId,
  soccerbetSerbiaStgId,
  spinAndWinProdId,
  spinAndWinStgId,
  teapuestoPeruProdId,
  teapuestoPeruStgId,
  teapuestoProdId,
  teapuestoStgId,
  themeConfig,
  toggleFeatureToken,
  unlayerProjectId,
  version,
  webManagerURI,
  webUri,
  yaJuegoProdId,
  yaJuegoStgId,
  aristocratZimbabweStgId,
  aristocratGabonStgId,
}
